import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    SimpleChanges,
    HostListener,
} from '@angular/core';

import { Observable, of, Subscription, BehaviorSubject } from 'rxjs';
import { take, debounceTime, tap, map, catchError, switchMap } from 'rxjs/operators';
import { BaseControl } from '../baseControl';
import { UniFieldLayout } from '@uni-framework/ui/uniform/interfaces';

import { IGroupConfig } from '@uni-framework/ui/unitable/controls/table-autocomplete';
import { KeyCodes } from '@app/services/common/keyCodes';
import { get, set } from 'lodash-es';

@Component({
    selector: 'uniform-autocomplete',
    templateUrl: './uniform-autocomplete.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniFormAutocomplete extends BaseControl {
    @ViewChild('dropdown') private dropdown: ElementRef;
    @ViewChild('inputElement', { static: true }) private inputElement: ElementRef;

    @Input() public field: UniFieldLayout;
    @Input() public model: any;
    @Input() public asideGuid: string;

    @Output() public readyEvent = new EventEmitter<UniFormAutocomplete>(true);
    @Output() public changeEvent = new EventEmitter<SimpleChanges>();
    @Output() public inputEvent = new EventEmitter<SimpleChanges>();
    @Output() public focusEvent = new EventEmitter<UniFormAutocomplete>(true);

    busy = false;
    isExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    lookupResults: any[] = [];

    options: any = {};
    private source: any[];
    private groupConfig: IGroupConfig;

    private selectedItem: any;
    private selectedIndex: number = -1;

    controlSubscription: Subscription;
    busyLoadingDisplayValueForModelValue: string;

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.isExpanded$.complete();
        this.controlSubscription?.unsubscribe();
    }

    public ngOnChanges(changes) {
        if (changes['field']) {
            this.readOnly$.next(this.field && this.field.ReadOnly);
            this.options = this.field.Options || {};
            this.source = this.options.source || [];
            this.groupConfig = this.options.groupConfig;
        }

        if (changes['model']) {
            const modelValue = get(this.model, this.field.Property);
            if (!modelValue) {
                this.selectedItem = undefined;
            }
        }

        if (this.field && this.model && this.options) {
            const modelValue = get(this.model, this.field.Property);
            const selectedValue = get(this.selectedItem, this.options.valueProperty);

            if (selectedValue !== modelValue && modelValue !== this.busyLoadingDisplayValueForModelValue) {
                this.busyLoadingDisplayValueForModelValue = modelValue;
                this.getInitialDisplayValue(modelValue).subscribe((result) => {
                    this.busyLoadingDisplayValueForModelValue = undefined;
                    if (result[0]) {
                        this.selectedItem = result[0];
                    }

                    this.createControl(this.template(this.selectedItem));
                    this.setupControlSubscription();
                });
            } else {
                this.createControl(this.template(this.selectedItem));
                this.setupControlSubscription();
            }
        }
    }

    private setupControlSubscription() {
        this.controlSubscription?.unsubscribe();
        this.controlSubscription = this.control.valueChanges
            .pipe(
                tap((query) => {
                    this.busy = true;
                    this.selectedIndex = query ? 0 : -1;
                    this.isExpanded$.next(true);
                }),
                debounceTime(150),
                switchMap((query) => this.lookup(query)),
            )
            .subscribe((items) => {
                this.lookupResults = items;
                this.busy = false;
                this.cd.markForCheck();
            });
    }

    private open() {
        this.isExpanded$.next(true);
        this.selectedIndex = -1;
        this.lookup().subscribe((items) => {
            this.lookupResults = items;
            this.cd.markForCheck();
        });
    }

    private close() {
        this.isExpanded$.next(false);
        this.selectedIndex = -1;
        this.lookupResults = [];
        this.updateControlValue();
        this.focus();
        this.cd.markForCheck();
    }

    onSearchButtonClick() {
        this.open();
        this.focusAndSelect();
    }

    lookup(query?: string): Observable<any[]> {
        if (!query) {
            query = '';
        }

        let result;
        if (this.options.search) {
            result = this.options.search(query);
        } else if (Array.isArray(this.source)) {
            let items = this.source;
            if (query?.length) {
                items = items.filter((item) => this.template(item)?.toLowerCase().includes(query.toLowerCase()));
            }

            result = items;
        }

        if (!result || !result.subscribe) {
            result = of(result || []);
        }

        return result.pipe(
            take(1),
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
            map((items: any[]) => {
                // Apparently the old search function allowed us to return the items inside
                // an array (Item[][]>) and I'm not sure its safe to remove this
                if (items && items[0] && Array.isArray(items[0])) {
                    items = items[0];
                }

                if (this.groupConfig) {
                    return this.groupItems(items || []);
                } else {
                    return items || [];
                }
            }),
        );
    }

    focus() {
        this.focusAndSelect();
    }

    blur() {
        this.inputElement?.nativeElement?.blur();
    }

    focusAndSelect() {
        if (this.inputElement?.nativeElement) {
            this.inputElement.nativeElement.focus();
            this.inputElement.nativeElement.select();
        }
    }

    onClickOutside() {
        if (this.isExpanded$.getValue()) {
            if (this.control.dirty && !this.control.value) {
                this.confirmSelection(null);
            } else {
                this.close();
            }
        }
    }

    template(item) {
        if (!item) {
            return '';
        }

        return this.options.template ? this.options.template(item) : get(item, this.options.displayProperty);
    }

    private getInitialDisplayValue(modelValue): Observable<any> {
        if (this.options.getDefaultData) {
            return this.options.getDefaultData(modelValue);
        } else {
            const selectedItem =
                Array.isArray(this.source) &&
                this.source.find((item) => {
                    return get(item, this.options.valueProperty) === modelValue;
                });

            return selectedItem ? of([selectedItem]) : of([]);
        }
    }

    private groupItems(items: any[]) {
        let groups = this.groupConfig.groups.map((groupInfo) => [{ ...groupInfo, isHeader: true }]);

        items.forEach((item) => {
            if (this.groupConfig.visibleValueKey ? item[this.groupConfig.visibleValueKey] : true) {
                const group = groups.find((group) => group[0].key === item[this.groupConfig.groupKey]);
                group?.push(item);
            }
        });

        // Remove groups that only contain header and concatenate to a single array
        return [].concat(...groups.filter((group) => group.length > 1));
    }

    private confirmSelection(item) {
        // Emit if item changed or the input was cleared
        if (item || (!this.control.value && this.control.dirty)) {
            this.selectedItem = item;

            const newValue = this.options?.emitItemInsteadOfValueProperty
                ? item || null
                : get(item, this.options.valueProperty) || null;

            const oldValue = get(this.model, this.field.Property);

            set(this.model, this.field.Property, newValue);

            if (newValue !== oldValue) {
                if (this.options?.events?.select) {
                    this.options.events.select(this.model, item);
                }

                this.emitChange(oldValue, newValue);
                this.emitInstantChange(oldValue, newValue, true);
            }
        }

        this.close();
    }

    private updateControlValue() {
        this.control.setValue(this.template(this.selectedItem), { emitEvent: false });
    }

    private handleTabOrEnter() {
        if (this.options?.editor && this.selectedIndex === this.lookupResults?.length) {
            this.options.editor(this.control.value);
            return;
        }

        if (this.busy && this.control.value) {
            this.isExpanded$.next(false);
            this.lookup(this.control.value).subscribe((items) => {
                this.confirmSelection(items[0]);
            });
        } else {
            const item = this.lookupResults && this.lookupResults[this.selectedIndex];
            if (item) {
                this.confirmSelection(item);
            } else {
                const shouldClearValue = this.control.dirty && !this.control.value;
                if (shouldClearValue) {
                    this.confirmSelection(null);
                } else {
                    this.close();
                }
            }
        }
    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent) {
        switch (event.keyCode) {
            case KeyCodes.TAB:
            case KeyCodes.ENTER:
                this.handleTabOrEnter();
                break;
            case KeyCodes.ESCAPE:
                event.stopPropagation(); // avoid closing dialogs etc when the user just wanted to close the search
                this.close();
                break;
            case KeyCodes.SPACE:
            case KeyCodes.F4:
                if (!this.isExpanded$.getValue()) {
                    event.preventDefault();
                    this.open();
                }
                break;
            case KeyCodes.UP_ARROW:
                event.preventDefault();
                if (this.selectedIndex > 0) {
                    this.selectedIndex--;
                    this.scrollToListItem();
                }
                break;
            case KeyCodes.DOWN_ARROW:
                event.preventDefault();
                if (event.altKey && !this.isExpanded$.getValue()) {
                    this.open();
                    return;
                }

                let limitDown = this.options?.editor ? this.lookupResults.length : this.lookupResults.length - 1;
                if (this.selectedIndex < limitDown) {
                    this.selectedIndex++;
                    this.scrollToListItem();
                }
                break;
        }

        this.cd.markForCheck();
    }

    private scrollToListItem() {
        const item: HTMLElement = this.dropdown?.nativeElement?.querySelector('[aria-selected=true]');
        item?.scrollIntoView({
            block: 'nearest',
        });
    }
}
