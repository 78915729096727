import { Injectable, inject } from '@angular/core';
import { loadExcelJS } from '@app/components/common/utils/excel/excel';
import { exportToFile } from '@app/components/common/utils/utils';
import { UniTableColumn, UniTableColumnType } from '@uni-framework/ui/unitable';
import { get } from 'lodash-es';
import { TableUtils } from './table-utils';

type ExcelColumn = {
    id: string;
    header: string;
};

@Injectable()
export class TableExportService {
    private tableUtils = inject(TableUtils);

    async export(data: any[], columns: UniTableColumn[]) {
        const excelJS = await loadExcelJS();

        const workbook = new excelJS.Workbook();
        const worksheet = workbook.addWorksheet('Eksport test');

        const groupingEnabled = data.some((item) => item._isGroupHeader);

        worksheet.columns = this.getExcelColumns(columns, groupingEnabled);

        for (const item of data) {
            // Add blank line above group headers
            if (item._isGroupHeader) {
                worksheet.addRow().commit();
            }

            const data = this.getRowData(item, columns, groupingEnabled);
            const row = worksheet.addRow(data);

            if (item._isGroupHeader) {
                row['_groupHeader'] = true;
                row.font = { bold: true };
            }

            row.commit();
        }

        // Auto size columns
        worksheet.columns.forEach((column) => {
            const colType = columns.find((col) => (col.alias || col.field) === column.key)?.type;

            let width = column.header.length;
            column.eachCell({ includeEmpty: true }, (cell) => {
                if (
                    Number(cell.value) &&
                    (colType === UniTableColumnType.Money || colType === UniTableColumnType.Number)
                ) {
                    const rounded = cell.value?.toFixed(2);
                    let cellWidth = rounded?.toString().length || 0;

                    // Add width for thousand separators and decimal points
                    cellWidth += Math.floor(cellWidth / 3) + 3;

                    width = Math.max(width, cellWidth);
                } else {
                    width = Math.max(width, cell.value?.toString().length || 0);
                }
            });

            width = Math.min(width, 50);
            column.width = width + 2;
        });

        // Color group headers
        if (groupingEnabled) {
            worksheet.eachRow((row) => {
                if (row._groupHeader) {
                    row.eachCell((cell) => {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'F0F5FA' },
                        };
                    });
                }
            });
        }

        const buffer = await workbook.xlsx.writeBuffer();
        exportToFile(undefined, 'export.xlsx', false, buffer);
    }

    private getExcelColumns(tableColumns: UniTableColumn[], groupingEnabled: boolean): ExcelColumn[] {
        const columns = tableColumns.map((tableColumn) => {
            const col: any = {
                key: tableColumn.alias || tableColumn.field,
                header: tableColumn.header,
                style: {
                    alignment: { indent: 1 },
                },
            };

            const type = tableColumn.type;
            if (type === UniTableColumnType.Money || type === UniTableColumnType.Number) {
                // Format numbers with thousand separators and two decimals. Color negative numbers in red.
                // Note that Excel will change thousand/decimal separator based on the user's locale.
                col.style.numFmt = '#,##0.00;[Red]-#,##0.00';
                col.style.alignment.horizontal = 'right';
            }

            return col;
        });

        if (groupingEnabled) {
            columns.unshift({
                key: '_groupLabel',
                header: 'Gruppe',
            });
        }

        return columns;
    }

    private getRowData(row: any, columns: UniTableColumn[], groupingEnabled: boolean) {
        const data = columns.map((col) => {
            if (col.type === UniTableColumnType.Number || col.type === UniTableColumnType.Money) {
                return get(row, col.alias || col.field, '');
            } else {
                return this.tableUtils.getColumnValue(row, col) || '';
            }
        });

        if (groupingEnabled) {
            data.unshift(get(row, '_groupLabel', ''));
        }

        return data;
    }
}
