import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { InvoicePaymentData, StatusCode, StatusCodeSupplierInvoice, Supplier, SupplierInvoice } from '@uni-entities';
import { UniHttp, RequestMethod, BizHttp } from '@uni-framework/core/http';
import { StatisticsService } from '../common/statisticsService';
import { UniModalService } from '@uni-framework/uni-modal/modalService';
import { ConfirmActions } from '@uni-framework/uni-modal/interfaces';
import { AuthService } from '@app/authService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { PaymentService } from './paymentService';
import { AccountService } from './accountService';

export interface SupplierInvoiceWorklistItem {
    type: 'SupplierInvoice' | 'File';
    id: number;
    label: string;
}

export interface SupplierInvoicePaymentDto {
    ID: number;
    Amount: number;
    AmountCurrency: number;
    PaymentBatchID: number;
    FromBankAccountID: number;
    PaymentMethodID: number;
    StatusCode: number;
    PaymentDate: string;
    JournalEntryNumber: string;
    JournalEntryID: number;
    CurrencyCode: string;
}

export enum StatusCodePaymentSupplierInvoice {
    Unpaid = 30109,
    TransferedToBank = 30110,
    PartlyPaid = 30111,
    Paid = 30112,
    Created = 30113,
}

@Injectable({ providedIn: 'root' })
export class SupplierInvoiceService extends BizHttp<SupplierInvoice> {
    private modelIDCache$: Observable<number>;
    private approversPerInvoiceIDCache$: Observable<{ [invoiceID: number]: string }>;

    // TODO: To be retrieved from database schema shared.Status instead?
    public statusTypes: Array<any> = [
        { Code: StatusCodeSupplierInvoice.Draft, Text: 'Opprettet', isPrimary: true },
        { Code: StatusCodeSupplierInvoice.ForApproval, Text: 'Tildelt', isPrimary: false },
        { Code: StatusCodeSupplierInvoice.Rejected, Text: 'Avvist', isPrimary: false },
        { Code: StatusCodeSupplierInvoice.Approved, Text: 'Godkjent', isPrimary: true },
        { Code: StatusCodeSupplierInvoice.Journaled, Text: 'Bokført', isPrimary: true },
        { Code: StatusCode.Completed, Text: 'Arkivert', isPrimary: false },
        { Code: StatusCode.Deleted, Text: 'Slettet', isPrimary: false },
    ];

    public paymentStatusCodes = [
        { Code: StatusCodePaymentSupplierInvoice.Unpaid, Text: 'Ubetalt' },
        { Code: StatusCodePaymentSupplierInvoice.TransferedToBank, Text: 'Overført til bank' },
        { Code: StatusCodePaymentSupplierInvoice.PartlyPaid, Text: 'Delbetalt' },
        { Code: StatusCodePaymentSupplierInvoice.Paid, Text: 'Betalt' },
        { Code: StatusCodePaymentSupplierInvoice.Created, Text: 'I betalingsliste' },
    ];

    worklist: SupplierInvoiceWorklistItem[];
    currentWorklistIndex: number;

    constructor(
        http: UniHttp,
        private statisticsService: StatisticsService,
        private paymentService: PaymentService,
        private modalService: UniModalService,
        private authService: AuthService,
        private accountService: AccountService,
    ) {
        super(http);
        super.disableCache();

        this.relativeURL = SupplierInvoice.RelativeUrl;
        this.entityType = SupplierInvoice.EntityType;
        this.DefaultOrderBy = null;

        this.authService.companyChange.subscribe(() => (this.approversPerInvoiceIDCache$ = undefined));
    }

    getStatusText(statusCode: number): string {
        const statusType = this.statusTypes.find((x) => x.Code === statusCode);
        return (statusType && statusType.Text) || '';
    }

    getPaymentStatusText(statusCode: number): string {
        const statusType = this.paymentStatusCodes.find((x) => x.Code === statusCode);
        return (statusType && statusType.Text) || '';
    }

    getModelID() {
        if (!this.modelIDCache$) {
            this.modelIDCache$ = this.statisticsService
                .GetAllUnwrapped(`model=Model&select=ID as ID&filter=Name eq 'SupplierInvoice'`)
                .pipe(
                    map((res) => res && res[0] && res[0].ID),
                    shareReplay(1),
                );
        }

        return this.modelIDCache$;
    }

    getApproversPerInvoiceID(): Observable<{ [invoiceID: number]: string }> {
        if (!this.approversPerInvoiceIDCache$) {
            this.approversPerInvoiceIDCache$ = this.getModelID().pipe(
                switchMap((modelID) => {
                    if (!modelID) {
                        return of([]);
                    }

                    const select = 'ID,EntityID as InvoiceID,stuff(User.DisplayName) as Approvers';
                    const dateFilter = `CreatedAt gt '${rigDate().subtract(1, 'year').format('YYYY-MM-DD')}'`;
                    const filter = `ModelID eq ${modelID} and Approvals.StatusCode ne 50150 and ${dateFilter}`;

                    return this.statisticsService.GetAllUnwrapped(
                        `model=Task&select=${select}&filter=${filter}&expand=Approvals.User`,
                    );
                }),
                map((res) => {
                    return res.reduce((object, item) => {
                        object[item.InvoiceID] = item.Approvers;
                        return object;
                    }, {});
                }),
                shareReplay(1),
            );
        }

        return this.approversPerInvoiceIDCache$;
    }

    restore(invoiceID: number) {
        return this.PostAction(invoiceID, 'restore');
    }

    assign(invoiceID: number, details: AssignmentDetails, isReAssignment?: boolean): Observable<boolean> {
        const action = isReAssignment ? 'reAssign-to' : 'assign-to';
        return this.ActionWithBody(invoiceID, details, action, RequestMethod.Post);
    }

    tryAutoAssign(invoiceID: number): Observable<boolean> {
        return this.PostAction(invoiceID, 'try-auto-assign');
    }

    journal(invoiceID: number) {
        return this.PostAction(invoiceID, 'journal');
    }

    public sendForPaymentWithData(supplierInvoiceId: number, invoicePaymentData?: InvoicePaymentData, hash?: string) {
        super.invalidateCache();
        return this.http
            .asPOST()
            .withBody(invoicePaymentData)
            .usingBusinessDomain()
            .withEndPoint(
                `${this.relativeURL}/${supplierInvoiceId}?action=sendForPaymentWithPaymentData` +
                    (!!hash ? '&hash=' + hash : ''),
            )
            .send()
            .pipe(map((response) => response.body));
    }

    creditInvoiceJournalEntry(invoiceID: number, keepData?: boolean, creditPayment?: boolean) {
        const params = `supplierInvoiceId=${invoiceID}&keepData=${keepData}&creditPayment=${creditPayment}`;
        return this.PostAction(null, 'credit-supplierinvoice-journalentry', params).pipe(map((res) => res || true));
    }

    getInvoicePaymentPostPostLines(supplierInvoiceID: number) {
        return this.GetAction(null, 'get-invoice-payment-postpost-lines', `id=${supplierInvoiceID}`);
    }

    payinvoice(invoiceID: number, paymentData: InvoicePaymentData) {
        return this.ActionWithBody(invoiceID, paymentData, 'payInvoice', RequestMethod.Put);
    }

    sendForPayment(invoiceID: number) {
        return this.PutAction(null, 'sendForPayment', `id=${invoiceID}`);
    }

    public getJournalEntyLinesBySupplierID(supplierID: number, year: number) {
        return this.Action(
            null,
            'get-supplierinvoices-details',
            `id=null&supplierID=${supplierID}&fromdate=${year}-1-1&todate=${year}-12-31`,
            RequestMethod.Get,
        );
    }

    public checkIfJournalEntryBelongsToSupplierInvoice(journalEntryNumber: string) {
        const params = `model=JournalEntryLine&select=SupplierInvoiceID as SupplierInvoiceID&filter=JournalEntryNumber eq '${journalEntryNumber}' and isnull(SupplierInvoiceID, 0) ne 0`;
        return this.statisticsService.GetAllUnwrapped(params).pipe(map((res) => (res && res[0]) || {}));
    }

    getReInvoicedData(ID: number) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`reinvoicing?action=get-stored-dtos&supplierInvoiceID=${ID}`)
            .send()
            .pipe(map((res) => res.body));
    }

    getInvoiceCountPerStatus(): Observable<{ StatusCode: number; Count: number }[]> {
        const params =
            'model=supplierinvoice&select=count(id) as Count,statuscode as StatusCode&filter=isnull(deleted,0) eq 0';
        return this.statisticsService.GetAllUnwrapped(params);
    }

    getInvoiceCountPerPaymentStatus(): Observable<{ unpaid: number; paid: number; paymentList: number }> {
        const select = [
            `sum(casewhen(PaymentStatus eq ${StatusCodePaymentSupplierInvoice.Unpaid},1,0)) as unpaid`,
            `sum(casewhen(PaymentStatus eq ${StatusCodePaymentSupplierInvoice.TransferedToBank} OR PaymentStatus eq ${StatusCodePaymentSupplierInvoice.PartlyPaid} OR PaymentStatus eq ${StatusCodePaymentSupplierInvoice.Created},1,0)) as paymentList`,
            `sum(casewhen(PaymentStatus eq ${StatusCodePaymentSupplierInvoice.Paid},1,0)) as paid`,
        ].join(',');

        const params = `model=SupplierInvoice&filter=statuscode ne 40001 and statuscode ne 40000&select=${select}`;
        return this.statisticsService.GetAllUnwrapped(params).pipe(map((res) => (res && res[0]) || {}));
    }

    public fetch(route: string, httpParams?: HttpParams): Observable<any> {
        return this.send(route, httpParams, 'GET');
    }

    public send(route: string, httpParams?: HttpParams, method = 'POST', body?: any): Observable<any> {
        let ht = this.http.asPOST();
        switch (method.toUpperCase()) {
            case 'GET':
                ht = this.http.asGET();
                break;
            case 'PUT':
                ht = this.http.asPUT();
                break;
            case 'DELETE':
                ht = this.http.asDELETE();
                break;
        }
        return ht
            .usingBusinessDomain()
            .withEndPoint(route)
            .send(body ? { body: body } : undefined, httpParams)
            .pipe(map((response) => response.body));
    }

    public isInvoicePaid(supplierInvoiceID: any) {
        const filter = `SupplierInvoiceID eq ${supplierInvoiceID}`;
        return this.statisticsService.GetAllUnwrapped(`model=Payment&select=ID&filter=${filter}`).pipe(
            map((res) => res?.length > 0),
            catchError(() => of(false)),
        );
    }

    public isDuplicateInvoice(invoiceNumber: any, supplierID: number, ID?: number) {
        const filter = `ID ne '${ID || 0}' and InvoiceNumber eq '${invoiceNumber}' and SupplierID eq '${supplierID}'`;
        return this.statisticsService.GetAllUnwrapped(`model=SupplierInvoice&select=ID&filter=${filter}`).pipe(
            map((res) => res?.length > 0),
            catchError(() => of(false)),
        );
    }

    public getDuplicatesIfAny(invoices: any) {
        let filters = [];
        invoices.forEach((invoice) => {
            filters.push(
                `(ID ne '${invoice.ID || 0}' and InvoiceNumber eq '${invoice.SupplierInvoiceInvoiceNumber}' and SupplierID eq '${invoice.SupplierID}')`,
            );
        });
        const filter = filters.join(' or ');

        return this.statisticsService
            .GetAllUnwrapped(`model=SupplierInvoice&select=InvoiceNumber as InvoiceNumber&filter=${filter}`)
            .pipe(
                map((res) => res),
                catchError(() => of([])),
            );
    }

    getInvoiceNumberValidator(formModel: BehaviorSubject<SupplierInvoice>) {
        let previousCheck = {
            invoiceNumber: undefined,
            supplierID: undefined,
            invoiceID: undefined,
            result: undefined,
        };

        return (invoiceNumber) => {
            if (!invoiceNumber) {
                return of(false);
            }

            const invoice = formModel?.value;

            const hasCheckedData =
                invoiceNumber === previousCheck.invoiceNumber &&
                invoice.SupplierID === previousCheck.supplierID &&
                invoice.ID === previousCheck.invoiceID;

            if (hasCheckedData) {
                return previousCheck.result;
            } else {
                const request = this.isDuplicateInvoice(invoiceNumber, invoice.SupplierID, invoice.ID).pipe(
                    map((isDuplicate) => {
                        if (isDuplicate) {
                            return { errorMessage: `Faktura ${invoiceNumber} er allerede registrert.` };
                        }
                    }),
                    shareReplay(1),
                );

                previousCheck = {
                    invoiceNumber,
                    supplierID: invoice.SupplierID,
                    invoiceID: invoice.ID,
                    result: request,
                };

                return request;
            }
        };
    }

    public isOCR(file: any): boolean {
        if (!file || !file.Name) {
            return false;
        }

        if (file.ContentType) {
            if (file.ContentType === 'application/xml') {
                return false;
            }
            if (file.ContentType.startsWith('image')) {
                return true;
            }
        }
        if (file.Extension && file.Extension === '.xml') {
            return false;
        }

        const ocrformats = ['pdf', 'docx', 'png', 'jpeg', 'jpg', 'gif', 'tiff'];
        const ending = file.Name.toLowerCase().split('.').pop();

        return ocrformats.indexOf(ending) >= 0 || ending.indexOf('pdf') >= 0;
    }

    public isEHF(file: any): boolean {
        if (!file.Name) {
            return false;
        }

        const name = (file.Name || '').toLowerCase();
        const contenttype = (file.ContentType || '').toLowerCase();
        return name.indexOf('.ehf') !== -1 || contenttype.startsWith('bis/');
    }

    private getSupplierName(invoiceID: number) {
        const query = `model=SupplierInvoice&filter=ID eq ${invoiceID}&select=Info.Name as Name&expand=Supplier.Info`;
        return this.statisticsService.GetAllUnwrapped(query).pipe(map((res) => res && res[0] && res[0].Name));
    }

    private getDataBeforeCredit(ID: number) {
        return forkJoin([
            this.getInvoicePayments(ID),
            this.getInvoicePaymentPostPostLines(ID),
            this.getSupplierName(ID),
        ]).pipe(
            switchMap(([payments, postpostLines, supplierName]) => {
                if (payments.length) {
                    return this.paymentService
                        .markPaymentsAsCredited(payments)
                        .pipe(map((payments) => [payments, postpostLines, supplierName]));
                } else {
                    return of([payments, postpostLines, supplierName]);
                }
            }),
        );
    }

    creditOrCorrectSupplierInvoice(
        invoiceID: number,
        invoice?: SupplierInvoice,
        isCorrectingInvoice?: boolean,
        foreCreditPayment?: boolean,
    ) {
        return this.getDataBeforeCredit(invoiceID).pipe(
            switchMap(([payments, postpostLines, supplierName]) => {
                const input = <ISupplierInvoicePaymentInput>{
                    invoiceID: invoiceID,
                    supplierName: supplierName,
                    invoice: invoice,
                    payments: payments,
                };

                if (payments.length || postpostLines.length) {
                    const hasPaymentFromBank = payments.some(
                        (payment) => !this.paymentService.isPaidManually(payment) && payment.StatusCode === 44004,
                    );
                    const hasPaymentInTransfer = payments.some((payment) => this.paymentService.isInTransfer(payment));

                    const journaledPayments = payments.filter(
                        (payment) => payment?.JournalEntryID !== null && !payment['_isCredited'],
                    );

                    const paymentJournalEntryNumbers = [
                        ...journaledPayments.map((payment) => payment?.JournalEntryNumber),
                        ...postpostLines.map((line) => line.JournalEntryNumber),
                    ];

                    input.paymentJournalEntryNumbers = paymentJournalEntryNumbers;
                    input.hasPaymentFromBank = hasPaymentFromBank;
                    input.hasPaymentInTransfer = hasPaymentInTransfer;

                    if (hasPaymentFromBank || hasPaymentInTransfer) {
                        return this.openAskCreditOrCorrectOnlyInvoiceModal(input, isCorrectingInvoice);
                    } else if (paymentJournalEntryNumbers.length) {
                        return this.openAskCreditOrCorrectInvoiceAndPaymentModal(
                            input,
                            isCorrectingInvoice,
                            foreCreditPayment,
                        );
                    } else {
                        return this.openConfirmCreditOrCorrectionModal(input, isCorrectingInvoice);
                    }
                } else {
                    return this.openConfirmCreditOrCorrectionModal(input, isCorrectingInvoice);
                }
            }),
        );
    }

    public correctSupplierInvoice(
        invoiceID: number,
        invoice: SupplierInvoice,
        creditPayment?: boolean,
        keepPayment?: boolean,
    ) {
        const params = `supplierInvoiceId=${invoiceID}&creditPayment=${creditPayment}&keepPayment=${keepPayment}`;
        return this.ActionWithBody(null, invoice, 'modify-booked-supplierinvoice', 'put', params);
    }

    private openAskCreditOrCorrectOnlyInvoiceModal(
        input: ISupplierInvoicePaymentInput,
        isCorrectingInvoice?: boolean,
    ): Observable<any> {
        let message = '';

        if (input.hasPaymentFromBank) {
            message = isCorrectingInvoice
                ? `Denne fakturaen er betalt i banken og knyttet til betalingsbilag ${input.paymentJournalEntryNumbers.join(', ')}. Ønsker du likevel å korrigere fakturaen?`
                : `Denne fakturaen er betalt i banken og knyttet til betalingsbilag ${input.paymentJournalEntryNumbers.join(', ')}. Dersom du krediterer fakturaen vil du få en betaling uten knytning til en faktura. Ønsker du likevel å kreditere fakturaen?`;
        } else {
            message = isCorrectingInvoice
                ? `Denne fakturaen er allerede lagt til betaling. Dersom du korrigerer denne bør du endre på betalingen slik at den samsvarer med fakturaen.`
                : `Denne fakturaen er allerede lagt til betaling. Dersom du krediterer bør denne betalingene stoppes manuelt i banken. Hvis du ikke stopper betalingen vil du få en betaling uten knytning til en faktura.`;
        }

        const obs$ = isCorrectingInvoice
            ? this.correctSupplierInvoice(input.invoiceID, input?.invoice)
            : this.creditInvoiceJournalEntry(input.invoiceID, true, false);

        return this.modalService
            .confirm({
                header: isCorrectingInvoice ? 'Korrigere faktura?' : 'Kreditere faktura?',
                message: message,
                checkbox: isCorrectingInvoice
                    ? null
                    : {
                          label: 'Jeg har forstått hva som skjer hvis jeg krediterer fakturaen',
                          mustConfirmToAccept: true,
                      },
                buttonLabels: {
                    accept: isCorrectingInvoice ? 'Korriger faktura' : 'Krediter faktura',
                    cancel: 'Avbryt',
                },
            })
            .onClose.pipe(
                switchMap((response) => {
                    if (response === ConfirmActions.ACCEPT) {
                        return obs$;
                    }
                    return of(false);
                }),
            );
    }

    private openAskCreditOrCorrectInvoiceAndPaymentModal(
        input: ISupplierInvoicePaymentInput,
        isCorrectingInvoice?: boolean,
        foreCreditPayment?: boolean,
    ): Observable<any> {
        const question = isCorrectingInvoice
            ? 'Ønsker du å slette betalingen'
            : 'Ønsker du å kreditere betalingen også';

        return this.modalService
            .confirm({
                header: isCorrectingInvoice ? 'Korrigere faktura' : 'Kreditere faktura?',
                message: `Denne fakturaen er knyttet til betalingsbilag ${input.paymentJournalEntryNumbers.join(', ')}. ${question}?`,
                buttonLabels: {
                    accept: isCorrectingInvoice ? 'Korriger faktura og slett betaling' : 'Krediter faktura og betaling',
                    reject: !foreCreditPayment
                        ? isCorrectingInvoice
                            ? 'Korriger kun faktura'
                            : 'Krediter kun faktura'
                        : null,
                    cancel: 'Avbryt',
                },
            })
            .onClose.pipe(
                switchMap((response) => {
                    const obs$ = isCorrectingInvoice
                        ? this.correctSupplierInvoice(
                              input.invoiceID,
                              input.invoice,
                              response === ConfirmActions.ACCEPT,
                          )
                        : this.creditInvoiceJournalEntry(input.invoiceID, true, response === ConfirmActions.ACCEPT);

                    if (response === ConfirmActions.ACCEPT || response === ConfirmActions.REJECT) {
                        return obs$;
                    }

                    return of(false);
                }),
            );
    }

    private openConfirmCreditOrCorrectionModal(
        input: ISupplierInvoicePaymentInput,
        isCorrectingInvoice?: boolean,
    ): Observable<any> {
        const hasPayments = input.payments.some((payment) => {
            return (
                payment.StatusCode === 44001 ||
                payment.StatusCode === 44003 ||
                payment.StatusCode === 44005 ||
                payment.StatusCode === 44012 ||
                payment.StatusCode === 44014
            );
        });

        const message = isCorrectingInvoice
            ? 'Vil du korrigere bokføringen av fakturaen?'
            : 'Vil du kreditere bokføringen for fakturaen? Fakturaen vil settes tilbake til "opprettet".';

        const warning = isCorrectingInvoice
            ? 'Leverandørfakturaen har en eller flere betalinger som vil bli slettet om du korrigerer.'
            : 'Leverandørfakturaen har en eller flere betalinger som vil bli slettet hvis du krediterer.';

        const obs$ = isCorrectingInvoice
            ? this.correctSupplierInvoice(input.invoiceID, input.invoice)
            : this.creditInvoiceJournalEntry(input.invoiceID, true, false);

        const options = {
            header: isCorrectingInvoice ? 'Korrigere faktura?' : 'Kreditere faktura?',
            message: message,
            closeOnClickOutside: false,
            checkbox: null,
            warning: hasPayments && warning,
        };

        if (options.warning && !isCorrectingInvoice) {
            options.checkbox = {
                label: 'Jeg har forstått hva som skjer hvis jeg krediterer fakturaen',
                mustConfirmToAccept: true,
            };
        }

        // No need to inform user (open confirm modal) if there are no payments that will be deleted
        // after correction of an invoice
        if (!hasPayments && isCorrectingInvoice) {
            return obs$;
        }

        return this.modalService.confirm(options).onClose.pipe(
            switchMap((response) => {
                if (response === ConfirmActions.ACCEPT) {
                    return obs$;
                }
                return of(false);
            }),
        );
    }

    getInvoicePayments(ID: number) {
        return forkJoin([this.getBankPayments(ID), this.getRegisteredPayments(ID)]).pipe(
            map(([bankPayments, registeredPayments]) => {
                // Use a map to avoid duplicates
                const paymentMap = new Map<number, SupplierInvoicePaymentDto>();
                bankPayments.forEach((payment) => paymentMap.set(payment.ID, payment));
                registeredPayments.forEach((payment) => paymentMap.set(payment.ID, payment));

                return Array.from(paymentMap.values()).sort((x, y) => {
                    return new Date(x.PaymentDate).getTime() - new Date(y.PaymentDate).getTime();
                });
            }),
        );
    }

    private getBankPayments(id: number): Observable<SupplierInvoicePaymentDto[]> {
        return this.statisticsService.GetAllUnwrapped(
            `model=Tracelink` +
                `&select=payment.Id as ID,payment.businessrelationid as BusinessRelationID,` +
                `payment.amount as Amount,payment.amountCurrency as AmountCurrency,` +
                `payment.PaymentBatchID as PaymentBatchID,` +
                `payment.FromBankAccountID as FromBankAccountID,` +
                `payment.statusCode as StatusCode,payment.paymentdate as PaymentDate,` +
                `journalEntry.JournalEntryNumber as JournalEntryNumber,` +
                `payment.JournalEntryID as JournalEntryID,CurrencyCode.Code as CurrencyCode` +
                `&filter=SourceEntityName eq 'SupplierInvoice' and ` +
                `DestinationEntityName eq 'Payment' and ` +
                `SourceInstanceID eq ${id} and Payment.ID gt 0 and Payment.BusinessRelationID eq Supplier.BusinessRelationID ` +
                `&join=Tracelink.DestinationInstanceId eq Payment.ID and Tracelink.SourceInstanceID eq SupplierInvoice.ID and ` +
                `Payment.BusinessRelationID eq BusinessRelation.ID and SupplierInvoice.SupplierID eq Supplier.ID and ` +
                `Payment.JournalEntryID eq JournalEntry.ID and Payment.CurrencyCodeID eq CurrencyCode.ID`,
        );
    }

    private getRegisteredPayments(id: number): Observable<SupplierInvoicePaymentDto[]> {
        return this.statisticsService.GetAllUnwrapped(
            `model=Payment` +
                `&select=ID as ID,amount as Amount,amountCurrency as AmountCurrency,paymentDate as PaymentDate,` +
                `FromBankAccountID as FromBankAccountID,` +
                `CurrencyCode.Code as CurrencyCode,statuscode as StatusCode,` +
                `JournalEntry.ID as JournalEntryID,` +
                `JournalEntry.JournalEntryNumber as JournalEntryNumber,` +
                `PaymentBatchID as PaymentBatchID` +
                `&filter=SupplierInvoiceID eq ${id} and StatusCode ne 44020` +
                `&join=Payment.JournalEntryID eq JournalEntry.ID and Payment.CurrencyCodeID eq CurrencyCode.ID`,
        );
    }

    getSumOfPayments(id: number): Observable<any> {
        return this.statisticsService
            .GetAllUnwrapped(
                `model=Tracelink` +
                    `&select=sum(payment.Amount) as Amount,` +
                    `sum(payment.AmountCurrency) as AmountCurrency` +
                    `&filter=SourceEntityName eq 'SupplierInvoice' and Payment.ID gt 0 and ` +
                    `SourceInstanceID eq ${id} and DestinationEntityName eq 'Payment' ` +
                    `and payment.StatusCode ne 44003 and ` +
                    `payment.StatusCode ne 44004 and payment.StatusCode ne 44006 and ` +
                    `payment.StatusCode ne 44010 and payment.StatusCode ne 44012 and ` +
                    `payment.StatusCode ne 44014 and payment.StatusCode ne 44018` +
                    `&join=Tracelink.DestinationInstanceID eq Payment.ID`,
            )
            .pipe(map((res) => res && res[0]));
    }

    itCanBeAnAsset(current: SupplierInvoice) {
        return of(current?.JournalEntry?.DraftLines?.[0]).pipe(
            switchMap((draftLine) => {
                if (!!draftLine?.Account?.AccountNumber) {
                    return of(draftLine.Account.AccountNumber);
                } else if (!!draftLine?.AccountID) {
                    return this.accountService.Get(draftLine.AccountID).pipe(map((account) => account.AccountNumber));
                } else return of('');
            }),
            catchError(() => of('')),
            map((accountNumber) => {
                const AccountNo = String(accountNumber);
                return AccountNo.startsWith('10') || AccountNo.startsWith('11') || AccountNo.startsWith('12');
            }),
            take(1),
        );
    }
}

export interface AssignmentDetails {
    Message?: string;
    TeamIDs?: number[];
    UserIDs?: number[];
    ApprovalRuleID?: number;
}

export interface ISupplierInvoicePaymentInput {
    invoiceID?: number;
    paymentJournalEntryNumbers?: any[];
    hasPaymentFromBank?: boolean;
    hasPaymentInTransfer?: boolean;
    supplierName?: string;
    invoice?: SupplierInvoice;
    payments?: any[];
}
